.alert-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.alert-box {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    width: 300px;
}

.alert-box p {
    font-size: 16px;
    margin-bottom: 10px;
}

.alert-box button {
    padding: 5px 30px;
    border: none;
    background: #d02429;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
    margin-top:10px;
}

.alert-box button:hover {
    background: #C1070C;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
}
